<template>
    <v-form ref="form">
        <slot></slot>
        <v-row>
            <v-col v-if="onPrev" class="flex-grow-0">
                <v-btn type="button" color="secondary" :outlined="true" @click="handlePrevButton">{{ prevButtonText }}</v-btn>
            </v-col >
            <v-col v-if="onNext">
                <v-btn type="button" color="secondary" @click="handleNextButton" :loading="loading" :disabled="loading">{{ nextButtonText }}</v-btn>
            </v-col>
        </v-row>
        </v-form>
</template>
<script>

export default {
    name: "StepperForm",
    props: {
        value: Object,
        completed: Boolean,
        nextButtonText: {
            default: "Volgende",
            type: String
        },
        prevButtonText: {
            default: "Vorige",
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        onPrev: {
            type: Function,
            default: null
        },
        onNext: {
            type: Function,
            default: null
        },
        traceFunction: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            hasBeenCompletedBefore: false
        }
    },
    emits: ['update:value', 'update:completed'],
    methods: {
        handleNextButton() {
            const complete = this.$refs.form.validate()
            if (complete && !this.hasBeenCompletedBefore) {
                this.hasBeenCompletedBefore = true
                this.traceFunction()
            }
            this.$emit('update:completed', complete)
            this.onNext(complete)
        },
        handlePrevButton() {
            this.$emit('update:completed', false)
            this.onPrev()
        },
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },
    }
}
</script>