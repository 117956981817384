<template>
  <StepperForm v-model="configurationForm" v-bind="$attrs" v-on="$listeners" :on-next="requestForm">
    <template v-slot:default>
      <v-row>
        <v-col cols="12" lg="7">
          <v-row>
            <v-col>
              <h2 class="mb-2">Configuratie Buildbase</h2>
            </v-col>
          </v-row>
          <v-row align="baseline" dense>
            <v-col cols="12">
              <label class="font-weight-bold">Pakketten:</label>
              <v-radio-group v-model="selectedPacket" class="pt-0" @change="onSelectedPacketChange">
                <div v-for="packet in packets" :key="packet.name">
                  <v-radio class="ml-4 mb-2" :label="translatePacketName(packet.name)" :value="packet.name"
                    color="primary" dense hide-details />
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="baseline" dense>
            <v-col cols="12">
              <label class="font-weight-bold">Oplossingen:</label>
              <v-checkbox v-for="buildbaseModule in computedModules" :key="buildbaseModule.name" class="ml-4"
                :value="buildbaseModule.name" v-model="allSelectedModules" color="primary" dense hide-details
                :disabled="buildbaseModule.forceSelected">
                <template v-slot:label>
                  {{ translateModuleName(buildbaseModule.name) }}
                  <BasicToolTip :description="getModuleTooltipDescription(buildbaseModule)" />
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <label class="required_star">Aantal gebruikers:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field required hide-details="auto" dense outlined type="number" min="1"
                v-model="configurationForm.seats" :rules="seatsRules" width="10px" class="max-w-128p"
                @input="recalculatePrice" />
            </v-col>
            <!-- TODO BUILDBASE-510 implement recurring payment
                Uncomment this to allow user to select monthly payment or payment once every x months
                Also remove the two columns below

            <v-col cols="12">
                <label class="required_star">Type abonnement:</label>
                <v-radio-group required hide-details="auto" v-model="configurationForm.subscriptionType" mandatory class="mt-0 pt-0" @change="recalculatePrice">
                    <v-radio label="Vaste Periode (In Maanden):" value="period" class="ml-6">
                        <template v-slot:label>
                            <span class="mr-2">Vaste Periode (In Maanden):</span> <v-text-field hide-details="auto" dense outlined type="number" v-model="configurationForm.period" :rules="periodRules" class="flex-grow-0 max-w-128p mr-auto" @input="recalculatePrice"/>
                        </template>
</v-radio>
<v-radio label="Maandelijks" value="monthly" class="ml-6" />
</v-radio-group>
</v-col> -->
            <v-col cols="12" sm="4">
              <label class="required_star">Aantal maanden:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field hide-details="auto" required dense outlined type="number" min="1"
                v-model="configurationForm.period" :rules="periodRules" class="flex-grow-0 max-w-128p mr-auto"
                @input="recalculatePrice" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="font-weight-bold">
          <label>{{ configurationForm.subscriptionType == "period" ? "Totale" : "Maandelijkse" }} kostprijs:</label>
          <v-text-field :rules="getRequiredRules('Kostprijs moet berekend zijn')" v-model="getFormattedPrice" readonly
            dense solo flat class="d-inline-block" />
        </v-col>
        <v-col cols="12">
          <EulaCheckbox v-if="isTrialToPaid" v-model="eulaAccepted" class="mb-3"/>
        </v-col>
      </v-row>
    </template>
  </StepperForm>
</template>

<script>
import StepperForm from '../shared/StepperForm.vue';
import { getRequiredRules, getBiggerThanZeroRules, getMinimalNumberRules } from '@/shared/utils/inputRulesUtils.js';
import { translatePacketName, translateModuleName } from "@/shared/utils/translateUtils";
import { getVatPercentage } from "@/shared/utils/priceUtils";
import { onlyUnique, differenceLeft } from "@/shared/utils/CollectionUtils"
import BasicToolTip from "@/components/shared/actions/BasicToolTip"
import PacketType from "@/shared/enums/packetType";
import routeNames from "@/router/RouteNames";
import EulaCheckbox from "@/components/shared/fields/EulaCheckbox.vue";

export default {
  name: "PaymentConfigurationForm",
  model: {
    prop: 'configurationForm',
    event: 'update:configurationForm'
  },
  props: {
    completed: Boolean,
    configurationForm: Object,
    organizationForm: Object,
    onNext: null,
  },
  emits: ['update:completed'],
  components: {
    StepperForm,
    BasicToolTip,
    EulaCheckbox
  },
  data() {
    return {
      activeUsers: null,
      selectedPacket: PacketType.NONE,
      selectedPacketPrice: null,
      selectedModules: [],
      buildbaseModules: [],
      eulaAccepted: false
    }
  },
  created() {
    this.$store.dispatch("paymentModule/fetchActivePackets")
    this.$store.dispatch("paymentModule/fetchActiveModules")
      .then((activeModules) => {
        this.buildbaseModules = activeModules
      })
  },
  methods: {
    getRequiredRules,
    translatePacketName,
    translateModuleName,
    onSelectedPacketChange() {
      this.selectedModules = []
    },
    recalculatePrice() {
      let priceCalculationRequest = {
        subscriptionElementNames: this.getSelectedSubscriptionElements,
        seats: this.configurationForm.seats,
        amountOfMonths: this.configurationForm.subscriptionType == "period" ? this.configurationForm.period : '1',
      }

      this.$set(this.configurationForm, 'expectedPrice', null)

      if (priceCalculationRequest.subscriptionElementNames.length > 0 && priceCalculationRequest.seats > 0 && priceCalculationRequest.amountOfMonths > 0) {
        this.$store.dispatch('paymentModule/calculateSubscriptionPriceExclVAT', priceCalculationRequest)
          .then((success) => {
            this.$set(this.configurationForm, 'expectedPrice', success?.data)
          })
      }
    },
    requestForm(completed) {
      if (this.getSeatsTooLow) {
        this.$store.dispatch('setError', 'U heeft ' + this.activeUsers + ' actieve gebruikers, gelieve minstens dit aantal in te vullen of gebruikers in uw organisatie te deactiveren')
      } else {
        this.onNext(completed)
      }
    },
    getActiveUsers() {
      this.$store.dispatch('usersModule/getActiveUsersFromOrganization').then((data) => {
        let activeUsersCount = data.length
        if (this.activeUsers === null) {
          this.configurationForm.seats = activeUsersCount
        }
        this.activeUsers = activeUsersCount
      })
    },
    onSelectionPacketChange() {
      this.configurationForm.selectedSubscriptionElements = this.pricesByName
      this.recalculatePrice();
    },
    removeDependingModules(dependingModules, blockedModules) {
      return dependingModules
        ?.filter(dm => !this.computedModules?.find(cm => dm == cm.name)?.dependsOnSubscriptionElementIds?.some(id => blockedModules.includes(id))
        )
    },
    getRequiredModules(dependingModules) {
      return this.computedModules?.filter(cm => dependingModules.includes(cm.name))
        .reduce((all, cm) => all.concat(cm.dependsOnSubscriptionElementIds), [])
    },
    getModuleTooltipDescription(dependingModule) {
      if (dependingModule?.dependsOnSubscriptionElementIds && dependingModule?.dependsOnSubscriptionElementIds.length > 0) {
        return "Afhankelijk van: " + dependingModule?.dependsOnSubscriptionElementIds?.map(id => translateModuleName(id))?.join(', ')
      } else {
        return "Deze module is onafhankelijk"
      }
    }
  },
  computed: {
    getSelectedSubscriptionElements() {
      let selectedSubscriptionElements = [...this.selectedModules]
      if (this.selectedPacket && this.selectedPacket != PacketType.NONE) {
        selectedSubscriptionElements.push(this.selectedPacket)
      }
      return selectedSubscriptionElements
    },
    packets() {
      // set packets default as selected (currently we don't support choice of a packet)
      let packetsList = this.$store.state.paymentModule.packets ? this.$store.state.paymentModule.packets.map(packet => ({
        ...packet,
        selected: false
      })) : []
      packetsList.unshift({ name: PacketType.NONE, price: 0, modules: [], selected: !packetsList.some(packet => packet.selected) })
      return packetsList
    },
    computedModules() {
      return this.buildbaseModules?.map(module => ({
        ...module,
        selected: null,
        forceSelected: this.getSelectedPacketModules?.includes(module.name)
      }))
    },
    getSelectedPacketModules() {
      return this.packets?.find(p => p.name == this.selectedPacket)?.modules
    },
    packetsAndModules() {
      return [...this.packets, ...this.computedModules]
    },
    pricesByName() {
      return new Map(this.getSelectedSubscriptionElements.map(sse => {
        const subElem = this.packetsAndModules?.find(v => v.name == sse)
        return [subElem?.name, subElem?.price]
      }))
    },
    periodRules() {
      return this.configurationForm.subscriptionType == "period" ? getRequiredRules('Periode moet ingevuld worden').concat(getBiggerThanZeroRules('Periode moet groter dan 0 zijn')) : getBiggerThanZeroRules('Periode moet groter dan 0 zijn')
    },
    isTrialToPaid(){
     return this.$route.name == routeNames.TRIAL_TO_PAID;
    },
    seatsRules() {
      if (this.$route.name == routeNames.TRIAL_TO_PAID) {
        this.getActiveUsers()
      }
      if (this.getSeatsTooLow) {
        return getMinimalNumberRules('U heeft ' + this.activeUsers + ' actieve gebruikers, gelieve minstens dit aantal in te vullen of gebruikers in uw organisatie te deactiveren', this.activeUsers)
      }
      return getBiggerThanZeroRules("Aantal gebruikers moet groter dan 0 zijn").concat(getRequiredRules('Aantal gebruikers moet ingevuld worden'))
    },
    vatPercentage() {
      return getVatPercentage(this.organizationForm.countryCode)
    },
    getFormattedPrice() {
      if (!this.configurationForm?.expectedPrice) {
        return null
      }
      return `€ ${this.configurationForm.expectedPrice} (excl. ${this.vatPercentage}% btw)`
    },
    getSeatsTooLow() {
      return this.activeUsers > 1 && this.activeUsers > this.configurationForm.seats
    },
    allSelectedModules: {
      get() {
        return [...this.selectedModules, ...this.getSelectedPacketModules]
      },
      set(newValues) {
        const removedModules = differenceLeft(this.selectedModules, newValues)
        const remainingModules = this.removeDependingModules(newValues, removedModules)
        const requiredModules = this.getRequiredModules(remainingModules, removedModules)
        const newAndRequiredModules = [...remainingModules, ...requiredModules]
        this.selectedModules = newAndRequiredModules.filter(v => v != null).filter(onlyUnique).filter(v => !this.getSelectedPacketModules.includes(v))
      }
    }
  },
  watch: {
    getSelectedSubscriptionElements() {
      this.recalculatePrice()
      this.onSelectionPacketChange()
    }
  }
}
</script>