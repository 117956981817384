<template>
    <v-row class="h-100 ma-0 pa-0 d-flex overflow-hidden">
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="4" class="d-flex background--yankee-blue">
            <v-img min-width="50px" contain src="@/assets/Logo_S2.png"></v-img>
        </v-col>
        <v-col :class="colClass" class="overflow-y-auto h-100vh">
            <slot></slot>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "LargeLogoContainer",
    props: {
        colClass: String
    }
}
</script>