<template>
    <StepperForm v-model="organizationForm" v-bind="$attrs" v-on="$listeners">
        <template v-slot:default>
            <v-row>
                <v-col cols="12" lg="7">
                    <v-row>
                        <v-col>
                            <h2 class="mb-2">Organisatie gegevens</h2>
                        </v-col>
                    </v-row>
                    <v-row align="baseline" dense>
                        <!-- TODO BUILDBASE-924: uncomment
                            <v-col cols="12" sm="4">
                            <label>Jouw functie:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="organizationForm.statute" :rules="getRequiredRules('Jouw functie moet ingevuld worden')"/>
                        </v-col> -->
                        <v-col cols="12" sm="4">
                            <label class="required_star">Straat:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="organizationForm.street" :rules="getRequiredRules('Straat moet ingevuld worden')"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Nummer:</label>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="organizationForm.number" :rules="getRequiredRules('Nummer moet ingevuld worden')"/>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <label>Bus:</label>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field hide-details="auto" dense outlined type="text" v-model="organizationForm.bus"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Postcode:</label>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="organizationForm.postalCode" :rules="getRequiredRules('Postcode moet ingevuld worden')"/>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <label class="required_star">Stad:</label>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="organizationForm.city" :rules="getRequiredRules('Stad moet ingevuld worden')"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Land:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <CountryCodeSelect required hide-details="auto" dense outlined type="text" v-model="organizationForm.countryCode" :rules="getRequiredRules('Land moet ingevuld worden')"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </StepperForm>
</template>

<script>
import StepperForm from '../shared/StepperForm.vue';
import CountryCodeSelect from '../shared/fields/CountryCodeSelect.vue';
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';

export default {
    name: "PaymentOrganizationForm",
    inheritAttrs: false,
    model: {
        prop: 'organizationForm',
        event: 'update:organizationForm'
    },
    props: {
        organizationForm: Object
    },
    components: { 
        StepperForm,
        CountryCodeSelect
    },
    methods: {
        getRequiredRules
    },
}
</script>